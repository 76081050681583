.card {
    position: relative;
  }
  .card img {
    display: block;
    border-radius: 6px;
  }
  
  /* front */
  .card .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;
  }
  .flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
  }
  
  /* back */
  .card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
    -webkit-box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.34); 
    box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.34);
  }
  .flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
  }
  